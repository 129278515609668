<template>
  <section id="dashboard-ecommerce">
    <b-card no-body class="mb-0">
      <b-card-body class="pb-1">
        <div class="featured" >
          <h4 style="font-family: montserrat !important;">
            Misyon ve Vizyonumuz
          </h4>
          <p style="font-family: montserrat !important;">
            <strong>Misyonumuz,</strong><br /><br />
            Otomotiv sektöründe müşteri isteklerini en üst seviyede karşılayacak
            ürün ve hizmeti sunmak, teknolojik yatırımları gerçekleştirerek
            işbirliği içinde bulunduğumuz kişi, kuruluş ve tüm toplum için
            değerler üreten ve paylaşan bir şirket olmak.<br /><br />

            <strong>Vizyonumuz,</strong><br /><br />

            Globalleşme sürecine giren dünyada, sektörümüzdeki teknolojik
            yenilikler, üstün kalite ve uzmanlaşmış bir kadro ile Türkiye'de
            halen bulunduğumuz yeri korumak ve yurt dışındaki otomotiv
            üreticilerine hizmet vermek.
          </p>
          <br />
          <p
            style="font-family: montserrat !important;font-weight: bold;text-align:center"
          >
            Genel Müdür
          </p>
          <p
            style="font-family: montserrat !important;font-weight: bold;text-align:center"
          >
            MUZAFFER TAŞOĞLU
          </p>
          <p style="font-family: montserrat !important;">
            Revizyon No: 1 <br />
            Revizyon Tarihi: 29/06/2006
          </p>
        </div>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BTableSimple,
  BTr,
  BTbody,
  BTd
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BTableSimple,
    BTr,
    BTbody,
    BTd
  }
};
</script>
